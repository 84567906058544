import { CategoryLoading } from "../components/SkeletonLoader";

import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { BsArrowLeft } from "react-icons/bs";

import React, { useContext, useState, useEffect } from "react";
const URL = "https://skyably.com/DEMO-APP/AQ-PLUS";

function ContactPage(props) {
  const { categoryId, categoryName } = useParams();
  const [Category, setCategory] = useState([]);
  const [loding, setloding] = useState(true);

  useEffect(() => {
    // alert(categoryId)

    async function fetchData() {
      setloding(true);

      await fetch(URL + "/APP-API/Product/GetStoreSubCategoryByID", {
        method: "post",
        header: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          cats_id: categoryId,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setCategory(responseJson.data);
          setloding(false);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <Navbar key="sm" bg="light" expand="sm" className="mb-3">
        <Container fluid>
          <Link className="text-decoration-none" to={"/"}>
            <BsArrowLeft size={24} color="black" />
          </Link>

          <Navbar.Brand href="#">Contact Us</Navbar.Brand>
        </Container>
      </Navbar>

      <Container>
        <Row>
          <Col>
            <>
              <Card.Body>
                <Card.Title>Contact Us</Card.Title>
                <Card.Text>
                  Thank you for your interest in AQ Plus mobile app. We are
                  always happy to hear from our users. Here are the ways you can
                  reach us:
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <div className="py-2">
                  {" "}
                  <h2>Contact Information</h2>{" "}
                </div>

                <ListGroup.Item>Mobile Number: 7320837359 </ListGroup.Item>
                <ListGroup.Item>WhatsApp Number: 7320837359 </ListGroup.Item>
                <ListGroup.Item>Email: contact@aqplus.in </ListGroup.Item>
                <ListGroup.Item>
                  Physical Location: Gorakhpur, Uttar Pradesh{" "}
                </ListGroup.Item>
              </ListGroup>

              <ListGroup className="list-group-flush">
                <div className="py-2">
                  {" "}
                  <h2>How to Reach Us</h2>{" "}
                </div>

                <ListGroup.Item>
                  For general inquiries, please send an email to
                  contact@aqplus.in. We will respond to your message as soon as
                  possible.{" "}
                </ListGroup.Item>
                <ListGroup.Item>
                  For technical support, please send an email to
                  support@aqplus.in. Our technical team will get back to you as
                  soon as possible.{" "}
                </ListGroup.Item>
                <ListGroup.Item>
                  If you prefer to speak to someone on the phone, please call us
                  at 7320837359. Our team is available to assist you during
                  business hours.{" "}
                </ListGroup.Item>
                <ListGroup.Item>
                  If you prefer to chat with us on WhatsApp, please send a
                  message to 7320837359. Our team will respond to your message
                  as soon as possible{" "}
                </ListGroup.Item>
              </ListGroup>
            </>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContactPage;
