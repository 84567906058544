import { CategoryLoading } from "./SkeletonLoader";
import Card from "react-bootstrap/Card";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation } from "react-router-dom";

import React, { useContext, useState, useEffect } from "react";
const URL = "https://skyably.com/DEMO-APP/AQ-PLUS";

function Category(props) {
  const [Category, setCategory] = useState([]);
  const [loding, setloding] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setloding(true);

      await fetch(URL + "/APP-API/Product/GetStoreCategory", {
        method: "post",
        header: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({}),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setCategory(responseJson.data);
          setloding(false);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      {loding ? (
        <>
          <CategoryLoading />
          <CategoryLoading />
          <CategoryLoading />
          <CategoryLoading />
        </>
      ) : (
        <Row className="my-4 p-2">
          {Category.map((item, key) => {
            return (
              <Col>
                <Link
                  className="text-decoration-none"
                  to={"/Category/" + item.id + "/" + item.name}
                >
                  <Card>
                    <Card.Img
                      variant="top"
                      src={URL + "/admin/images/category_images/" + item.image}
                    />
                    <Card.Body>
                      <Card.Title className="text-center">
                        <strong>{item.name}</strong>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
}

export default Category;
