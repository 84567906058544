import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useToast } from "@chakra-ui/react";

import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Cookies from "universal-cookie";

import { BsArrowLeft } from "react-icons/bs";

import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router";

import React, { useContext, useState, useEffect } from "react";
const URL = "https://skyably.com/DEMO-APP/AQ-PLUS";
const cookies = new Cookies();

function LoginPage(props) {
  const navigate = useNavigate();

  const toast = useToast();
  const { categoryId, categoryName } = useParams();
  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [job, setjob] = useState("");
  const [city, setcity] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const SignUpData = () => {
    console.log("click", name);

    if (name == "") {
      toast({
        title: "Name is requird",
        description: "Please Fill",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });

      setisLoading(false);
    } else if (mobile == "") {
      toast({
        title: "Mobile Number is requird",
        description: "Please Fill",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setisLoading(false);
    } else if (mobile.length < 10) {
      toast({
        title: "10 Digit Mobile Number is requird",
        description: "Please Fill",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setisLoading(false);
    }
    if (city == "") {
      toast({
        title: "City is requird",
        description: "Please Fill",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setisLoading(false);
    }
    if (job == "") {
      toast({
        title: "Work Job is requird",
        description: "Please Fill",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setisLoading(false);
    } else {
      fetch(URL + "/APP-API/Product/insertUser", {
        method: "post",
        header: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          mobile: mobile,
          job: job,
          city: city,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("res", responseJson);

          cookies.set("isUserLogin", true, { maxAge: 9999999999 });
          cookies.set("userID", responseJson.data[0].id, {
            maxAge: 9999999999,
          });
          cookies.set("name", responseJson.data[0].name, {
            maxAge: 9999999999,
          });
          cookies.set("mobile", responseJson.data[0].mobile, {
            maxAge: 9999999999,
          });

          navigate("/");
        });
    }
  };

  return (
    <>
      <Container>
        <Row className="my-2  mt-4 p-1 rounded">
          <Col>
            <Row className="justify-content-md-center">
              <h3 className="text-center">Please fill your information</h3>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col className="p-2 ">
            <Card className="p-2 ">
              <div
                data-bs-spy="scroll"
                data-bs-target="#navbar-example2"
                data-bs-offset="0"
                class="scrollspy-example"
                tabindex="0"
              >
                <Form>
                  <Form.Group className="mb-3" controlId="formGroupName">
                    <Form.Label>Name (नाम)</Form.Label>
                    <Form.Control
                      onChange={(e) => setname(e.target.value)}
                      type="name"
                      placeholder="Enter Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupTel">
                    <Form.Label>Mobile (मोबाइल)</Form.Label>
                    <Form.Control
                      onChange={(e) => setmobile(e.target.value)}
                      type="tel"
                      placeholder="Enter Mobile"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupTel">
                    <Form.Label>Work Job (क्या काम करते है )</Form.Label>
                    <Form.Control
                      onChange={(e) => setjob(e.target.value)}
                      type="job"
                      placeholder="Work Job"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupCity">
                    <Form.Label>City (शहर का नाम )</Form.Label>
                    <Form.Control
                      onChange={(e) => setcity(e.target.value)}
                      type="city"
                      placeholder="Enter City"
                    />
                  </Form.Group>

                  {isLoading ? (
                    <Button
                      isLoading
                      loadingText="Sending OTP"
                      class="btn btn-success btn-lg rounded d-flex align-items-center justify-content-center w-100"
                      variant="outline"
                    />
                  ) : (
                    <button
                      type="button"
                      onClick={() => SignUpData()}
                      class="btn btn-primary "
                    >
                      Signup
                    </button>
                  )}
                </Form>
              </div>
            </Card>
          </Col>
        </Row>

        <Row className="my-2  mt-4 p-1 rounded">
          <Col>
            <Row className="justify-content-md-center">
              <Image
                className="text-center"
                height={30}
                src="IMG/logo-big.png"
                fluid
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LoginPage;
