import Carousel from "react-bootstrap/Carousel";
import { PromoLoading } from "./SkeletonLoader";

import React, { useContext, useState, useEffect } from "react";
const URL = "https://skyably.com/DEMO-APP/AQ-PLUS";

function Banner() {
  const [banners, setbanners] = useState([]);
  const [loding, setloding] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setloding(true);

      await fetch(URL + "/APP-API/Product/OffersBanner", {
        method: "post",
        header: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({}),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setbanners(responseJson);
          setloding(false);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      {loding ? (
        <PromoLoading />
      ) : (
        <Carousel>
          {banners.map((item, key) => {
            return (
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-100"
                  src={URL + "/admin/images/offer-image/" + item.image}
                  //   alt="First slide"
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      )}
    </>
  );
}

export default Banner;
