import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavCanVas from "./NavCanVas";

function Header() {
  return (
    <>
      <Navbar key="sm" bg="light" expand="sm" className="mb-3">
        <Container fluid>
          <Row className="justify-content-md-center">
            <Image
              className="text-center"
              src="https://skyablyitsolution.com/wp-content/uploads/2024/10/cropped-SKYABLY-1000-x-300-px-1-155x51.png"
              fluid
            />
          </Row>

          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
          <NavCanVas />
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
