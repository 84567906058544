import { CategoryLoading } from "../components/SkeletonLoader";

import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { BsArrowLeft } from "react-icons/bs";

import React, { useContext, useState, useEffect } from "react";
const URL = "https://skyably.com/DEMO-APP/AQ-PLUS";

function AboutPage(props) {
  const { categoryId, categoryName } = useParams();
  const [Category, setCategory] = useState([]);
  const [loding, setloding] = useState(true);

  useEffect(() => {
    // alert(categoryId)

    async function fetchData() {
      setloding(true);

      await fetch(URL + "/APP-API/Product/GetStoreSubCategoryByID", {
        method: "post",
        header: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          cats_id: categoryId,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setCategory(responseJson.data);
          setloding(false);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <Navbar key="sm" bg="light" expand="sm" className="mb-3">
        <Container fluid>
          <Link className="text-decoration-none" to={"/"}>
            <BsArrowLeft size={24} color="black" />
          </Link>

          <Navbar.Brand href="#">About</Navbar.Brand>
        </Container>
      </Navbar>

      <Container>
        <Row>
          <Col>
            <>
              <Card.Body>
                <Card.Title>About AQ Plus</Card.Title>
                <Card.Text>
                  AQ Plus is a comprehensive business listing mobile app that
                  helps users find jobs, properties, rentals, and other
                  information related to their queries. This app is designed to
                  simplify the search process for users who are looking for
                  specific information.
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <div className="py-2">
                  {" "}
                  <h2>Features</h2>{" "}
                </div>

                <ListGroup.Item>
                  Job Listings: Users can search for job listings by category,
                  location, and other filters. The app provides a list of job
                  openings that match the user's criteria.
                </ListGroup.Item>
                <ListGroup.Item>
                  Property Listings: Users can search for properties by
                  location, type, and other filters. The app provides a list of
                  properties that match the user's criteria.
                </ListGroup.Item>
                <ListGroup.Item>
                  Rental Listings: Users can search for rentals by location,
                  type, and other filters. The app provides a list of rental
                  properties that match the user's criteria.
                </ListGroup.Item>
                <ListGroup.Item>
                  Easy Navigation: The app has a user-friendly interface that
                  makes it easy for users to navigate through the app and find
                  the information they need.{" "}
                </ListGroup.Item>
                <ListGroup.Item>
                  Save Searches: Users can save their searches for future
                  reference and receive notifications when new listings are
                  added that match their criteria.{" "}
                </ListGroup.Item>
                <ListGroup.Item>
                  Reviews and Ratings: The app allows users to review and rate
                  businesses, helping other users make informed decisions.{" "}
                </ListGroup.Item>
                <ListGroup.Item> </ListGroup.Item>
                <ListGroup.Item> </ListGroup.Item>
              </ListGroup>

              <ListGroup className="list-group-flush">
                <div className="py-2">
                  {" "}
                  <h2>Benefits</h2>{" "}
                </div>

                <ListGroup.Item>
                  Time-Saving: AQ Plus app saves users time by providing them
                  with a comprehensive list of job openings, properties,
                  rentals, and other information related to their queries.{" "}
                </ListGroup.Item>
                <ListGroup.Item>
                  Easy to Use: The app is easy to use, with a simple and
                  intuitive interface.{" "}
                </ListGroup.Item>
                <ListGroup.Item>
                  Comprehensive Listings: AQ Plus app provides users with a
                  comprehensive list of businesses and services, making it
                  easier for them to find what they are looking for.{" "}
                </ListGroup.Item>
                <ListGroup.Item>
                  User Reviews: The app allows users to read reviews and ratings
                  of businesses, helping them make informed decisions.{" "}
                </ListGroup.Item>
              </ListGroup>

              <Card.Img variant="top" src="IMG/logo-big.png" />
            </>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AboutPage;
