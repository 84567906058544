import React, { useContext, useState, useEffect } from "react";

import { CategoryLoading } from "../components/SkeletonLoader";

import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import { BsArrowLeft } from "react-icons/bs";
import ImageUploader from "react-images-upload";
import Cookies from "universal-cookie";
import { useToast } from "@chakra-ui/react";
// import { useNavigate,  } from 'react-router';

// import { useNavigate, useLocation } from 'react-router';

const cookies = new Cookies();

const URL = "https://skyably.com/DEMO-APP/AQ-PLUS";

function AddListing(props) {
  const toast = useToast();

  const navigate = useNavigate();
  // const location = useLocation();

  const { categoryId, subcategoryId, subcategoryName, categoryName } =
    useParams();
  const [Category, setCategory] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const [listingDetails, setlistingDetails] = useState({
    user_id: cookies.get("userID"),
    product_name: "",
    mobile: "",
    whatsapp: "",
    website: "",
    products_description: "",
    image: [],
    parent_id: categoryId,
    category_id: subcategoryId,
    isImage: 0,
  });

  const onChangeImage = (pictureFiles) => {
    setlistingDetails({ ...listingDetails, image: pictureFiles });
  };

  useEffect(() => {
    // alert(categoryId)

    async function fetchData() {
      setisLoading(true);

      await fetch(URL + "/APP-API/Product/GetStoreSubCategoryByID", {
        method: "post",
        header: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          cats_id: categoryId,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setCategory(responseJson.data);
          setisLoading(false);
        });
    }
    fetchData();
  }, []);

  const AddListing = () => {
    console.log("data", listingDetails);

    if (listingDetails?.product_name == "") {
      toast({
        title: "Please Fill Title",
        description: "Requird",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } else if (listingDetails?.mobile == "") {
      toast({
        title: "Please Fill Mobile Number",
        description: "Requird",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } else if (listingDetails?.products_description == "") {
      toast({
        title: "Please Fill Details",
        description: "Requird",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      setisLoading(true);

      const formData = new FormData();

      listingDetails?.image &&
        listingDetails?.image.map((item, i) => {
          formData.append(`image[]`, item, item.name);
        });
      formData.append("user_id", listingDetails?.user_id);
      formData.append("product_name", listingDetails?.product_name);
      formData.append("mobile", listingDetails?.mobile);
      formData.append("whatsapp", listingDetails?.whatsapp);
      formData.append(
        "products_description",
        listingDetails?.products_description
      );
      formData.append("parent_id", listingDetails?.parent_id);
      formData.append("category_id", listingDetails?.category_id);
      formData.append("website", listingDetails?.website);

      console.log("data", listingDetails);

      fetch(URL + "/APP-API/Product/addListing", {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log("respond", responseJson)
          setlistingDetails({
            user_id: cookies.get("userID"),
            product_name: "",
            mobile: "",
            whatsapp: "",
            website: "NO",
            products_description: "",
            image: { length: 0 },
            parent_id: categoryId,
            category_id: subcategoryId,
            isImage: 0,
          });

          toast({
            title: "Listing Complete ",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });

          setisLoading(false);
          navigate(-1);
        })
        .catch((error) => {
          //  console.error(error);
        });
    }
  };

  return (
    <>
      <Navbar key="sm" bg="light" expand="sm" className="mb-3">
        <Container fluid>
          <Link className="text-decoration-none" to={"/"}>
            <BsArrowLeft size={24} color="black" />
          </Link>

          <Navbar.Brand href="#">
            Post {subcategoryName} in {categoryName}
          </Navbar.Brand>
        </Container>
      </Navbar>

      {isLoading ? (
        <>
          <CategoryLoading />
          <CategoryLoading />
          <CategoryLoading />
          <CategoryLoading />
        </>
      ) : (
        <Row className=" p-2">
          <Col>
            <div
              data-bs-spy="scroll"
              data-bs-target="#navbar-example2"
              data-bs-offset="0"
              class="scrollspy-example"
              tabindex="0"
            >
              <Form>
                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Title (Requird)</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setlistingDetails({
                        ...listingDetails,
                        product_name: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Enter Title"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupTel">
                  <Form.Label>Mobile (Requird)</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setlistingDetails({
                        ...listingDetails,
                        mobile: e.target.value,
                      })
                    }
                    type="mobile"
                    placeholder="Enter Mobile"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupTel">
                  <Form.Label>WhatsApp (Optional)</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setlistingDetails({
                        ...listingDetails,
                        whatsapp: e.target.value,
                      })
                    }
                    type="whatsapp"
                    placeholder="Enter WhatsApp"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupTel">
                  <Form.Label>Website (Optional)</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setlistingDetails({
                        ...listingDetails,
                        website: e.target.value,
                      })
                    }
                    type="web"
                    placeholder="Enter Website"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupTel">
                  <Form.Label>Details (Requird)</Form.Label>
                  <Form.Control
                    as="textarea"
                    cols="30"
                    rows="5"
                    onChange={(e) =>
                      setlistingDetails({
                        ...listingDetails,
                        products_description: e.target.value,
                      })
                    }
                    aria-label="With textarea"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupTel">
                  <Form.Label>Image (Optional)</Form.Label>

                  <ImageUploader
                    withIcon={true}
                    buttonText="Choose Images"
                    onChange={onChangeImage}
                    imgExtension={[".jpg", ".jpeg", ".png", ".gif"]}
                    maxFileSize={5242880}
                    singleImage={true}
                    withPreview={true}
                  />
                </Form.Group>

                {isLoading ? (
                  <Button
                    isLoading
                    loadingText="Sending OTP"
                    class="btn btn-success btn-lg rounded d-flex align-items-center justify-content-center w-100"
                    variant="outline"
                  />
                ) : (
                  <button
                    type="button"
                    onClick={() => AddListing()}
                    class="btn btn-dark "
                  >
                    Submit
                  </button>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

export default AddListing;
