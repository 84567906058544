import { CategoryLoading } from "../components/SkeletonLoader";

import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { RWebShare } from "react-web-share";

import { BsArrowLeft } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { BsFillShareFill } from "react-icons/bs";
import { BsLaptop } from "react-icons/bs";

import Cookies from "universal-cookie";

import React, { useContext, useState, useEffect } from "react";
const URL = "https://skyably.com/DEMO-APP/AQ-PLUS";
const cookies = new Cookies();
function ListingPage(props) {
  const { categoryId, subcategoryId, subcategoryName, categoryName } =
    useParams();
  const [Category, setCategory] = useState([]);
  const [loding, setloding] = useState(true);

  useEffect(() => {
    // alert(categoryId)

    async function fetchData() {
      // handleOnSubmit()
      setloding(true);

      await fetch(URL + "/APP-API/Product/fetchListingByCategory", {
        method: "post",
        header: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          subcategoryId: subcategoryId,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("list", responseJson);
          setCategory(responseJson.listData);
          setloding(false);
        });
    }
    fetchData();
  }, []);

  const handleOnSubmit = async (image) => {
    let headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // headers.append('Origin','http://localhost:3001');

    const response = await fetch("" + image + "", {
      mode: "no-cors",
      credentials: "include",
      method: "POST",
      headers: headers,
    });
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], "share.png", { type: blob.type });
    console.log(file);
    if (navigator.share) {
      await navigator
        .share({
          title: "title",
          text: "your text",
          url: "url to share",
          files: [file],
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error in sharing", error));
    } else {
      console.log(`system does not support sharing files.`);
    }
  };

  // useEffect(()=> {
  //   if (navigator.share === undefined) {
  //     if (window.location.protocol === 'http:') {
  //       window.location.replace(window.location.href.replace(/^http:/, 'https:'));
  //     }
  //   }
  // }, []);

  return (
    <>
      <Navbar key="sm" bg="light" expand="sm" className="mb-3">
        <Container fluid>
          <Link className="text-decoration-none" to={"/"}>
            <BsArrowLeft size={24} color="black" />
          </Link>

          <Navbar.Brand href="#">
            {subcategoryName} in {categoryName}
          </Navbar.Brand>
        </Container>
      </Navbar>

      {loding ? (
        <>
          <CategoryLoading />
          <CategoryLoading />
          <CategoryLoading />
          <CategoryLoading />
        </>
      ) : (
        <div className="my-4 p-2">
          {Category.map((item, key) => {
            return (
              <Col className="my-2">
                <Link className="text-decoration-none">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={URL + "/" + item.product_image}
                    />
                    <Card.Body>
                      <Card.Title className="text-center">
                        <strong>{item.product_name}</strong>
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        {item.products_description}
                      </Card.Subtitle>

                      <Col className="d-flex justify-content-around">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href = "tel:" + item.mobile;
                          }}
                          class="btn btn-primary btn-sm active"
                        >
                          {" "}
                          <BsFillTelephoneFill size={18} color="white" />{" "}
                        </a>

                        {item.mobile2 == null || item.mobile2 == "" ? null : (
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.href = "tel:" + item.mobile2;
                            }}
                            class="btn btn-danger btn-sm active"
                          >
                            <BsFillTelephoneFill size={18} color="white" />
                          </Link>
                        )}

                        {item.whatsapp == null || item.whatsapp == "" ? null : (
                          <Link
                            onClick={(e) => {
                              e.preventDefault();

                              window.location.href =
                                "https://api.whatsapp.com/send?text=Hi My Name is " +
                                cookies.get("name") +
                                " and my contact number is " +
                                cookies.get("mobile") +
                                " I saw this on *AQ Plus App* . I need More Information About ->  " +
                                item.product_name +
                                "&phone=+91" +
                                item.whatsapp;
                            }}
                            data-action="share/whatsapp/share"
                            class="btn btn-success btn-sm active"
                          >
                            <BsWhatsapp size={18} color="white" />
                          </Link>
                        )}

                        {item.whatsapp == null || item.whatsapp == "" ? null : (
                          <div
                            onClick={() =>
                              window.Android.ShareWithImageFunc(
                                item.product_image,
                                "https://play.google.com/store/apps/details?id=com.apk.aqplusapp"
                              )
                            }
                            class="btn btn-dark btn-sm active"
                          >
                            <BsFillShareFill size={18} color="white" />
                          </div>
                        )}
                      </Col>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            );
          })}
        </div>
      )}

      {/* <div className="pb-0 pt-2 px-3 letItFlowInAir">
                <Link 
                  to={"/add-listing/" + categoryId +"/" +categoryName+"/" +subcategoryId+"/" +subcategoryName}
                >
                  <div class="rounded shadow bg-success p-3 text-white">
                    <div class="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                       
                        <h6 class="m-0 ml-2">
                         Post {subcategoryName} in {categoryName}
                        </h6>
                      </div>{" "}
                     
                    </div>
                  </div>
                </Link>
              </div> */}
    </>
  );
}

export default ListingPage;
