
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from "react-router-dom";

import Offcanvas from 'react-bootstrap/Offcanvas';
import Cookies from 'universal-cookie';
const cookies = new Cookies();



function NavCanVas() {
  return (
    <>
    
      
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-sm`}
              aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                  Hello {cookies.get("name")}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
              

                  <Nav.Link href="#">   <Link className="text-decoration-none" to={"/"} > Home  </Link></Nav.Link>
                  <Nav.Link href="#"><Link className="text-decoration-none" to={"/About"} > About  </Link></Nav.Link>
                  <Nav.Link href="#"><Link className="text-decoration-none" to={"/Contact"} > Contact  </Link></Nav.Link>
            
                </Nav>
       
              </Offcanvas.Body>
            </Navbar.Offcanvas>

   
  


    </>
  );
}

export default NavCanVas;