import { CategoryLoading } from "../components/SkeletonLoader";

import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import { BsArrowLeft } from "react-icons/bs";

import React, { useContext, useState, useEffect } from "react";
const URL = "https://skyably.com/DEMO-APP/AQ-PLUS";

function SubCategoryListPage(props) {
  const { categoryId, categoryName } = useParams();
  const [Category, setCategory] = useState([]);
  const [loding, setloding] = useState(true);

  useEffect(() => {
    // alert(categoryId)

    async function fetchData() {
      setloding(true);

      await fetch(URL + "/APP-API/Product/GetStoreSubCategoryByID", {
        method: "post",
        header: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          cats_id: categoryId,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setCategory(responseJson.data);
          setloding(false);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <Navbar key="sm" bg="light" expand="sm" className="mb-3">
        <Container fluid>
          <Link className="text-decoration-none" to={"/"}>
            <BsArrowLeft size={24} color="black" />
          </Link>

          <Navbar.Brand href="#">{categoryName}</Navbar.Brand>
        </Container>
      </Navbar>

      {loding ? (
        <>
          <CategoryLoading />
          <CategoryLoading />
          <CategoryLoading />
          <CategoryLoading />
        </>
      ) : (
        <Row className="my-4 p-2">
          {Category.map((item, key) => {
            return (
              <Col>
                <Link
                  className="text-decoration-none"
                  to={
                    "/listing/" +
                    categoryId +
                    "/" +
                    categoryName +
                    "/" +
                    item.id +
                    "/" +
                    item.name
                  }
                >
                  <Card>
                    <Card.Img
                      variant="top"
                      src={URL + "/admin/images/category_images/" + item.image}
                    />
                    <Card.Body>
                      <Card.Title className="text-center">
                        <strong>{item.name}</strong>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
}

export default SubCategoryListPage;
