import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./Page/HomePage";
import LoginPage from "./Page/LoginPage";
import SubCategoryListPage from "./Page/SubCategoryListPage";
import AboutPage from "./Page/AboutPage";
import ContactPage from "./Page/ContactPage";
import ListingPage from "./Page/ListingPage";
import AddListing from "./Page/AddListing";

import { ChakraProvider } from "@chakra-ui/react";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/Category/:categoryId?/:categoryName?"
            element={<SubCategoryListPage />}
          />
          <Route
            path="/listing/:categoryId?/:categoryName?/:subcategoryId?/:subcategoryName?"
            element={<ListingPage />}
          />
          <Route
            path="/add-listing/:categoryId?/:categoryName?/:subcategoryId?/:subcategoryName?"
            element={<AddListing />}
          />
          <Route path="/Login" element={<LoginPage />} />
          <Route path="/About" element={<AboutPage />} />
          <Route path="/Contact" element={<ContactPage />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
