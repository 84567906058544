import React, { useContext, useState, useEffect } from "react";

import Header from "../components/header";
import Banner from "../components/banner";
import Category from "../components/category";

import Cookies from "universal-cookie";
import { useNavigate, useLocation } from "react-router";
import { PromoLoading, CategoryLoading } from "../components/SkeletonLoader";
import Base64 from "../helper/EncodeDecode";
const URL = "https://skyably.com/DEMO-APP/AQ-PLUS";

const cookies = new Cookies();

function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const userid = await cookies.get("isUserLogin");
      if (userid == undefined || userid == null || userid == "") {
        navigate("/Login");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const userID = cookies.get("userID");
    const inserted = sessionStorage.getItem("fcmInserted");

    const settingFCMtoken = async () => {
      const token = window?.Android && (await window.Android.getDeviceToken());
      const userIDdd = userID;
      setUserFCMtoken(token, userIDdd);
    };

    settingFCMtoken();
  }, []);

  const setUserFCMtoken = async (token = "token website", provider_id) => {
    sessionStorage.setItem("fcmInserted", true);
    fetch(URL + "/APP-API/Product/updateToken", {
      method: "post",
      header: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token,
        provider_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("token ===>", responseJson);
      })
      .catch((error) => {
        //  console.error(error);
      });
  };

  return (
    <>
      <Header />

      <Banner />

      <Category />
    </>
  );
}

export default HomePage;
